import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import ScheduleSection from '../components/ScheduleSection'

class TestimonialsPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Testimonials - Single Men & Women"
          description={siteDescription}
        />
        <div className="testimonials-page page-heading text-center">
          <div className="container">
            <h1 className="text-white m-0">Testimonials</h1>
            <hr />
            <h4 className="text-white m-0">Well Said</h4>
          </div>
        </div>
        <div className="page-content bg-light pb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="row justify-content-around">
                  <div className="col-md-12 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> When Donna says love
                        happens here, she is right on a number of levels.
                        <br />
                        <br />
                        First, Donna herself is one of the most loving people I
                        know. She lives her life with such integrity and
                        compassion that she sets an example for the rest of us
                        to follow. I honestly do not think that Donna has one
                        vengeful bone in her (if I may be so forward) incredibly
                        fit body.
                        <br />
                        <br />
                        Secondly, Donna takes her huge heart and uses it to
                        match other hearts together. Donna does not just match
                        people, she matches souls. This takes astute talent and
                        experience, both of which Donna has in spades.
                        <br />
                        <br />
                        If you are serious about finding a soul partner, trust
                        your heart with Donna. She brings the true art of
                        matchmaking to the community. This art is something no
                        one, no other organization, and certainly no online
                        matching service remotely compares to.
                        <br />
                        <br />
                        In this age of false advertising and swiping left or
                        right, Donna carries on the legacy of matchmaking in the
                        truest sense.
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Tessa</h5>
                    </div>
                  </div>
                  <div className="col-md-12 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> Donna is passionate
                        about her work...period. Rarely do I experience a person
                        that is so motivated by her desire to fulfill her
                        mission of "causing" a real relationship to happen. I've
                        discovered her real "pay" is the joy she feels when she
                        creates a match that two people then grow into a true
                        relationship. NOTE--manage your expectations!!! Donna
                        sets up a match that has POTENTIAL based on similar
                        attitudes. But then--naturally--it is only when the two
                        people meet several times that the "real chemistry" is
                        revealed. My initial expectations were something like
                        "OK , for the money I'm paying you, I'm expecting 10
                        absolutely Perfect Matches that I can then chose which
                        one I like best." How STUPID of me!!! I finally realized
                        that she was eliminated a huge amount of the up-front
                        work (time, energy, money, efforts) in just finding a
                        person of similar interests and attitudes--leaving me
                        (and the match) to discover how our chemistry fit
                        together. Talking with several others who have been
                        success with Donna (getting close to 800 married couples
                        I think)--I came to realize the huge role she was
                        playing in my life and I'm very grateful...and now have
                        several very delightful "friends" as well as a
                        relationship that is blossoming.
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Frank</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> Perfectly Match is a
                        great matchmaking service! Donna takes the pressure off
                        and doesn't put anyone on the spot. Donna is passionate
                        about helping people get together.
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Delma</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> Donna Shugrue
                        follows a path for finding a partner that I firmly
                        believe in. Her interview skills are finely tuned, and
                        her integrity and knowledge superb.
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- BJ</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> Perfectly Matched is
                        a fabulous matchmaking service. Calling Donna was the
                        best decision I ever made. I received quality matches
                        and ultimately met the love of my life. I have no doubt
                        that without Donnas help I would not have met such a
                        perfect match on my own. There is no time frame on her
                        service and you get to meet quality, compatible people
                        based on her profile test that you may not ever find on
                        your own. I highly recommend this service for any
                        singles serious about finding love.
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Mark</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> Highly Recommend! In
                        a business where there are naturally no guarantees that
                        any two people will end up having "that spark" (and any
                        business that tells you otherwise is being dishonest,)
                        Donna has been amazing. She has been up front, honest,
                        knowledgeable, experienced and has gone above and
                        beyond! A great choice for anyone who is over the online
                        dating thing or who doesn't want to pay an arm and a leg
                        to whatever impersonal, nationwide company comes along
                        next.
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Jessica</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> I'm using Perfectly
                        Match and have found Donna to take her job very
                        seriously. I have meet some great women but for some
                        reason it just didn't click. Is that on Donna no way
                        people need to be honest in the interview. Plus I have
                        changed the way i have approached dating with the help
                        of Donna. I have stopped being less about looks and more
                        about the person. Lets face it we all want that perfect
                        person and no one is perfect. Yes i would like to find
                        someone sooner than later. I have used other date sites
                        but with Donna i feel she does understand what i'm
                        looking for. But it's my call to make it work or not.
                        That's dating!
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Larry</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> I was a client and
                        the service was outstanding! There are literally
                        HUNDREDS of positive client experiences, which you won't
                        see here because only people who complain and don't take
                        any personal responsibility or initiative to make
                        themselves more appealing write to blame Donna for their
                        personal decisions, appearance, and behavior. Since her
                        service has no timeframe, clients can remain active
                        until a successful match is made.
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Joe</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> Donna is amazingly
                        insightful and her match test is spot on. She had great,
                        high quality matches for me and I would recommend her
                        services to anyone who is single.
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Kathy</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> What I appreciate
                        about Perfectly Matched is Donna's personalized service.
                        I trust her judgment and respect her talents as a
                        matchmaker. The feedback after each meeting has been
                        very insightful and informative. She made a real effort
                        to get to know me and I have met some great quality men.
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Linda</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> I really believe in
                        Donna’s Compatibility Test. I can’t believe how much I
                        have in common with Terry. We think so much alike and
                        get along so well it's almost scary.
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Dave</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> I met her in March,
                        married her in June and everyday since has been a
                        honeymoon.
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Jay</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> Many times I would
                        meet someone and walk away not knowing whether they were
                        or were not interested. With Donna as a third party and
                        her input and involvement before and after each of the
                        initial meetings, you always know where you stand.
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Susan</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> I was getting into
                        relationships too quickly and being intimate right away.
                        One of Donna's favorite sayings is, "The ones that start
                        fast, end fast." When I did meet a lady I was very
                        interested in I decided to invest in friendship for the
                        first three months, which is what Donna suggested, and
                        we have been together for almost two years.
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Chris</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> The only thing
                        missing in my life was having someone special. I have a
                        great job, great home, great friends but I did not have
                        time to try to meet someone on my own. My mother bought
                        me a membership to Perfectly Matched for Christmas last
                        year and in May I met Mark.....so far so good.
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Heather</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> Someone at my
                        workplace decided to make copies of my online dating
                        profile and pass it around the office. It was very
                        upsetting to say the least. I like that Donna's service
                        is totally confidential and discreet and none of my
                        information is on a computer. Plus, I’m meeting some
                        sensational ladies!
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Tim</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> You introduced me to
                        Danny in 1992. Our sixteenth wedding anniversary is
                        coming up. We have 3 beautiful children, Jeni will be
                        14, Sara is 12 and Levi is 10. We’ve been through a
                        lot...but don’t think we could have made it without each
                        other. Thanks for putting us together Donna!
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Shelley</h5>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="testi-content h-100">
                      <p className="mb-0">
                        <i className="fas fa-quote-left" /> I am very happy with
                        Donna's service. I have been on multiple dating web
                        sites for the past couple of years and have discovered
                        that Donna's personalized match making works far and
                        away better compared with dating sites. Donna's rating
                        system is much more accurate than what people put on
                        their dating web sites. I have met and enjoyed all of
                        Donna's matches for me. I'd highly recommend Perfectly
                        Matched!
                        <i className="fas fa-quote-right" />
                        <br />
                      </p>
                      <div className="testi-triangle" />
                      <h5 className="mt-3 mb-0">- Ray</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScheduleSection />
      </Layout>
    )
  }
}
export default TestimonialsPage

export const testimonialsPageQuery = graphql`
  query testimonialsPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
